.molecule-breakingnews {
  margin-bottom: 3rem;
  background-color: $color-secondary;

  &__link {
    color: $color-white;
    display: flex;
    align-items: center;
    padding: .5rem 2rem;
    font-size: 1.6rem;

    &:hover {
      color: $color-main;
      text-decoration: none;
    }

    &:after {
      content: "\276F";
      width: 1.6em;
      height: 1.6em;
      margin-right: -10px;
      text-align: center;
      transition: all .35s;
    }

    span {
      font-weight: bold;
      margin-right: 1rem;
    }
  }

  &__textline {
    margin: 0;
    flex: 1;
    line-height: 2.2rem;
  }

}
