.organism-lead {

  .row.container__row {

    .col-md-4.teaser1 {
      margin-bottom: 1.5rem;
    }

    &.mediun-rectangle {

      .col-md-4.teaser2 {
        margin-bottom: 0;
      }
    }
  }

  &__adcontainer {
    height: 100%;
    padding-bottom: 1.5rem;
  }

  &__ad {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;

    height: 100%;
    min-height: 25rem;

    background-color: lightgrey;
  }
}