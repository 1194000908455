@include media-breakpoint-up(lg) {
    .organism-header {

        &__logo {
            max-width: 40rem;

            a {
                margin-top: 15px;

                svg {
                    height: 7.5rem;
                }
            }
        }

        &__description {
            font-size: 1.5rem;
            max-width: 25rem;
        }

        &__weathercontainer {
            display: block;
            flex-direction: unset;
            align-self: unset;
            padding-bottom: 0;
            font-size: 1.2rem;
        }
    }
}
