.template-article__tags {
  font-size: 1.6rem;
  text-align: center;
  display: flex;
  padding-top: 15px;

  .template-article__tags-headline {
    display: block;
    margin-right: 1.5rem;
  }

  ul,
  li {
    list-style: none;
  }

  ul {
    padding-left: 0;
    display: inline-block;
  }

  .template-article__tag-item {
    display: inline-block;

    &:not(:last-child):after {
      content: "\00b7";
      font-size: 2.4rem;
      line-height: 1.6rem;
      padding: 0 1.2rem;
      display: inline-block;
      vertical-align: middle;
      color: #999;
    }
  }
}
