// COLORS
$color-black: #000000 !default;
$color-white: #FFFFFF !default;
$color-light-grey: #f7f7f7 !default; // TODO: many display's can only show 16-240 ef = 247 = invisible
$color-light-grey2: #e7e7e7 !default; // e7 = 231 and will be visible on all display's
$color-grey: #999999 !default;
$color-grey-svg: #b8b8b8;

$color-main: #000F87 !default;
/*$color-secondary: #008CFA !default;*/
$color-secondary: #04a8fb !default;
$color-text: #333333 !default;
$color-text-input: #727272 !default;

$color-progress-off: #75A0A8 !default;  // TODO: delete?

// FONT
$color-deep-purple: #353060 !default;
$color-button-normal: #004066 !default;
$color-blue-velvet: #006DA1 !default;
$color-date: #858585 !default;
$default-font-size: 1.6rem !default;

$default-font-light: 'SourceSansPro-Light', sans-serif !default;
$default-font-regular: 'SourceSansPro-Regular', sans-serif !default;
$default-font-semibold: 'SourceSansPro-SemiBold', sans-serif !default;
$default-font-bold: 'SourceSansPro-Bold', sans-serif !default;
$default-font-icon: 'OMIconFont', sans-serif !default;


// Overrides
// @todo check values
$grid-breakpoints: (
    xs: 0px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
) !default;

$container-max-widths: (
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
) !default;

$grid-gutter-width: 2rem !default;

/*
$copy-20: 2rem/2.4rem 'BrixSans', sans-serif;
$copy-18: 1.8rem/2.4rem 'BrixSans', sans-serif;
$copy-14: 1.4rem/1.9rem 'BrixSans', sans-serif;
*/

