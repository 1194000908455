.molecule-article {

  &__imagecontainer {
    width: 100%;
    position: relative;
    padding-bottom: 56.2%;
    margin-top: 2.5rem;
  }

  &__image {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__nativead,
  &__omplus {

    .atom-logo {
      margin-right: .7rem;

      svg {
        width: 5rem;
        height: 1.5rem;
        float: left;
        fill: $color-secondary;
      }
    }
  }

  &__nativead {
    .atom-logo {
      svg {
        fill: $color-grey-svg;
      }
    }
  }

  h2,
  h3 {
    margin: 2rem 0;
    font-size: 2rem;
  }

  &__content {

    h1 {
      font-size: 2.4rem;
      font-family: $default-font-bold;
      margin-bottom: 1rem;
    }

    h2 {
      font-family: $default-font-bold;
      /* TODO: place in _globlal.scss ?? */
      font-size: 2.4rem;
      margin-bottom: 1.5rem;
    }

    p {
      margin-bottom: 1rem;

      .om-font-icon {
        color: $color-secondary;
      }
    }
  }

  &__regionlink {
    display: flex;
    color: $color-main;
    font-weight: 600;
    font-size: $default-font-size;
    line-height: 1.4rem;
    margin-bottom: 1rem;

    a {
      color: $color-main;
      text-decoration: none;

      &:hover {
        color: $color-secondary;
      }
    }
  }

  &__caption {
    font-size: 1.5rem;
    font-family: $default-font-light;
  }

  &__date {
    color: $color-date;
  }

  &__paragraph-intro {
    color: $color-main;
    font-weight: 600;
  }

  ul.article_bullet-list, ul {
    font-size: $default-font-size;
    margin-bottom: 1.5rem;

    li::before {
      content: "\2022";
      color: $color-main;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  ol {
    list-style: decimal;
    font-size: $default-font-size;
    margin-bottom: 1.5rem;
  }

  blockquote {
    margin: 2rem 0;
    border-left: 2px solid $color-main;
    padding-left: 1.5rem;
    font-family: SourceSansPro-SemiBold, sans-serif;
    font-size: 2rem;

    span {
      display: block;
      font-style: italic;
      font-size: $default-font-size;
    }
  }

  &__regionlist {
    list-style: none;
    padding: 0;

    li {
      display: inline;

      &:before {
        display: none !important;
      }

      &:not(:last-child):after {
        content: " / ";
        color: #999;
      }

      a {
        color: $color-main;

        &:hover {
          text-decoration: none;
          color: $color-secondary;
        }
      }
    }
  }

  hr.separator {
    border-bottom: 2px solid $color-main;
    margin: 3.5rem auto;
    max-width: 18rem;
  }

  .image-container {
    display: table;
    margin: auto;
    padding-top: 1rem;
    width: 100%;
    text-align: center;
  }

  .image-caption {
    font-size: 1.5rem;
    display: table-caption;
    caption-side: bottom;
    line-height: normal;
    padding-top: .5rem;
    padding-bottom: 2rem;
    font-family: SourceSansPro-Light,sans-serif;
  }

  .image-source {
    font-size: 1.2rem;
    display: block;
    line-height: normal;
    padding-top: .5rem;
    padding-bottom: 0;
    font-family: SourceSansPro-Light,sans-serif;
  }

  .listicle-item {
    padding-top: 2rem;
  }

  iframe {
    margin-bottom: 2rem;
  }

  #om-online\.de_leaderboard_D {
    iframe {
      padding: 0;
    }
  }
}
