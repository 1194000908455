@include media-breakpoint-up(md) {
  .organism-footer {
    font-size: 2rem;
    padding: 40px 0 0;

    .logos-title {
      font-size: 1.5rem;
    }

    .om-font-icon {
      margin-right: 1.4rem;
      font-size: 3.5rem;
      line-height: 8rem;
    }

    ul.footer-links {
      li {
        line-height: 2.4rem;
      }
    }

    .mobile-footer-links {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
    }

    .style {
      &__mtLogo,
      &__ovLogo {
        svg {
          width: 20rem;
          height: 3rem;
        }
      }
      &__omLogoFooter {
        svg {
          width: 22rem;
          height: 10rem
        }
      }
      &__iconSpotify {
        height: 100%;
        a {
          svg {
            width: 3.5rem;
            height: 3.5rem;
          }
        }
      }
    }

    .padding-left {
      padding-left: 0;
    }
  }

  .social-media-links {

    a:not(:last-child) {
      padding-right: 10px;
    }

    a:last-child {

      i {
        margin-right: 0;
      }
    }
  }
}