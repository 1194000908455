.atom-menuitem {

  &__item {
    flex-shrink: 0;
    transition-duration: 0.5s;

    &.dropdown > a::after {
      content: "\276F";
      color: $color-white;
      line-height: 1.6rem;
      padding-left: .5rem;
      display: inline-block;
      font-size: 1.3rem;
      float: right;
      margin-left: 1.4rem;
      transform: rotate(90deg);
      transition: all .35s;
      padding-top: .6rem;
    }

    &.active {
      background-color: $color-secondary;

      a  {
        color: $color-white !important;
        border-bottom: 1px solid transparent !important;
      }
    }

  }

  &__link {

    &,
    &:link,
    &:visited {
      color: $color-white;
      text-decoration: none;
      padding: .5rem 2rem;
      display: block;
    }

    &:hover {
      background-color: $color-secondary;

      &:after {
        text-decoration: none;
      }
    }

    &.active {
      background-color: $color-secondary;
    }
  }

  &__dropdown {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: all .5s ease;
    display: none;
    padding: 1.5rem 0;
    border: 1px solid rgba($color-black,.15);
    border-radius: .4rem;
    max-width: 30rem;
    background-color: $color-white;
  }

  &__item:hover > &__dropdown,
  &__item &__dropdown:hover {
    visibility: visible;
    opacity: 1;
    display: block;

   a {
      color: $color-text;
      padding: .5rem 1.5rem;
      margin: 0 1.5rem;
      border-bottom: 1px solid #e9ecef; // TODO: color variable
      background-color: transparent;

    }

    li:hover {
      background: $color-light-grey;
    }

    &__item:not(:last-child) &__link {
      border-bottom: 1px solid red;
    }

  }

}

