.container.footer {
  background-color: $color-main;
}

.organism-footer {
  display: block;
  font-size: 1rem;
  padding: 25px 0;
  font-family: $default-font-regular;

  svg {
    fill: $color-white !important;
  }

  a,
  a:visited {
    color: $color-white;
  }

  .logos-title {
    color: $color-white;
    margin: 0;
    font-size: 1.3rem;
    padding: .5rem 0;
  }

  .footer-links {
    margin: 0;
    padding: 0;
    line-height: 1.6rem;

    li {
      margin: 0;
      padding: 0;
      line-height: 2rem;
    }
  }

  .mobil-links-container {
    display: flex;
    align-items: center;
  }

  .mobile-footer-links {
    margin: 0;
    padding: 0;
    line-height: 1.6rem;

    li {
      margin: 0;
      padding: 0;
      line-height: 2rem;
      width: 50%;
      font-size: 1.4rem;
      min-width: 80px;
      max-width: 120px;
      white-space: nowrap;
    }
  }

  .style {
    &__mtLogo,
    &__ovLogo {
      svg {
        height: 3rem;
        width: 100%;
      }
    }
    &__omLogoFooter {
      svg {
        width: 14rem;
        height: 7rem;
      }
    }
    &__iconSpotify {
      display: inline-block;
      position: relative;
      height: 38px;
      a {
        display: flex;
        svg {
          width: 3rem;
          height: 3rem;
          align-self: center;
        }
      }
    }
  }

  .margin-top {
    margin-top: 3rem;
  }

  .social-media-links {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    a:hover {
      text-decoration: none;
      }
  }

  .om-font-icon {
    margin: 0 0.8rem 0 0;
    line-height: 4rem;
    font-size: 3rem;
  }
}
