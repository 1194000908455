.molecule-metalinks {
  text-align: right;
  font-size: 1.4rem;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;

  &__list {
    padding: 0;
    margin: 0 1rem 0 0;
    height: 3rem;
    display: flex;

    .atom-logo {
      width: 70px;
      height: 30px;

    }
  }

  &__item {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 1.5rem;
    }

    .atom-logo {
      a:hover {
        svg {
          fill: $color-secondary;
        }
      }

    }

    &.accountContainer {
      display: none;

      &.show {
        display: block;
      }
    }

    &.hide {
      display: none;
    }
  }

  &__sociallinks {
    margin: 0 3rem 0 4.5rem;
    height: 3rem;

    .om-font-icon {
      margin: 0;
      font-size: 3rem;
      color: rgba($color-grey, .8);
    }
  }

  &__link {
    color: $color-grey;
    display: inline-block;
    line-height: 3rem;
    height: 3rem;

    &:hover {
      text-decoration: none;

      .om-font-icon {
        color: $color-secondary;
      }

      svg {
        fill: $color-secondary !important;
      }
    }
  }

  &__search {

    z-index: 200;

    .molecule-metalinks__list {
      margin: 0 0 0 1rem;
    }

    .molecule-metalinks__item {
      margin: 0;

      &:last-child {
        margin-left: .5rem;
      }

      &.search-box {
        position: relative;
        min-width: 4.5rem;
      }

      .togglemenu__search {
        display: flex;
        font-size: 1.4rem;
        line-height: 3rem;
        right: 0;
        margin-right: 0;

        .search-container {
          display: flex;
          width: 7.5rem;

          .search-btn {
            z-index: 10;
            padding-right: .5rem;
            text-decoration: none;
          }

          span {
            position: absolute;
            right: 0;
            color: #999;
            padding-right: .5rem;
          }
        }

        label {
          width: 3rem;
          height: 3rem;
          border: none;
          min-width: 7.3rem;

          input {
            font-size: 1.4rem;
            border-radius: inherit;
            background-color: transparent;
            padding-right: 6.5rem;
            position: absolute;
            right: 3.8rem;
            z-index: 9;
          }

          &:hover {
            width: 25rem;
            background-color: #f7f7f7;

            svg {
              fill: $color-secondary;
            }

            .search-btn {
              text-decoration: none;

              span {
                color: $color-secondary;
              }
            }

          }
        }

        .atom-logo {
          position: absolute;
          right: 4rem;
        }

        svg {
          fill: rgba($color-grey, .8);;
          width: 2.6rem !important;
        }
      }
    }

    .molecule-metalinks__link {
      display: flex;

      i {
        margin: 0;
        font-size: 3.5rem;
        line-height: 3rem;
        color: rgba($color-grey, .8);
      }

      svg {
        width: 1.6rem;
        margin: 0 1rem 0 1rem;
        fill: rgba($color-grey, .8);
      }

      &.logout {
        display: none;
      }

      &.show {
        display: flex;
      }

      &.hide {
        display: none;
      }
    }
  }

  &__ePaper {
    display: none;
    margin: 0.3rem 0 0 -1rem;

    a {
      display: flex;
      color: $color-main;

      &:hover {
        text-decoration: none;

        svg {
          fill: $color-secondary
        }
      }

      .atom-logo {
        height: 17px;
        width: 30px;
      }
    }

    &__text {
      line-height: 26px;
    }

    div {
      display: inline-block;
    }
  }
}
.molecule-metalinks-ad {
  &__ads {
    position: absolute;
    top: 114px;
    right: 0px;
    z-index: 100;
    a {
      font-family: SourceSansPro-Regular;
      color: $color-grey;
      line-height: 32px;
      &:hover {
        text-decoration: none;
        color: $color-secondary
      }
    }
  }



}
