.atom-picture {
  display: inline-block;
}

.atom-content-picture__link {
  display: inline-block;
}

.atom-content-picture__figure {
  display: inline-block;
}
