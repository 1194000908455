.molecule-articletags {
  padding: .5rem 0;
  margin-top: 1rem;
  font-size: $default-font-size;

  &__heading {
    font-size: 2rem;
  }

  ul {
    padding: 0;

    li {

      &:not(:last-child):after {
        content: "/";
        font-size: $default-font-size;
      }

      &:before {
        display: none;
      }

    }
  }
}