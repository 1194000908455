.accordion-cards {

    .card {
        margin-bottom: 1rem;
    }

    .accordion--header {
        color: $color-main;
        background-color: #f6f6f7;
        cursor: pointer;
        padding: 1.5rem;
        width: 100%;
        text-align: left;
        border: none;
        outline: none;
        transition: 0.5s;
        position: relative;

        h2 {
            font-size: 1.6rem;
        }
    }

    .accordion--header:after {
        content: "\276F";
        position: absolute;
        transform: rotate(0);
        -moz-transition: all 0.25s ease;
        -webkit-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
        right: 10px;
        top: 10px;
        font-size: 16px;
    }

    .accordion--header.active:after {
        transform: rotate(90deg);
    }

    .accordion--body {
        padding: 0;
        background-color: #ecedee;
        overflow: hidden;
        border-top: 1px solid #dddddd;
        max-height: 0;
        opacity: 0;
        -webkit-transition: all 0.35s ease;
        -moz-transition: all 0.35s ease;
        transition: all 0.35s ease;
    }

    .accordion--body.opened {
        max-height: 600px;
        opacity: 1;
        padding: 1.5rem;

    }
}
