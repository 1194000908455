.molecule-toptopics {
  display: flex;
  padding: .5rem;
  font-size: 1.4rem;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;


  &::-webkit-scrollbar {
    display: none;
  }

  &__header {
    white-space: nowrap;
  }

  &__list {
    padding-left: 2.5rem;
    margin: 0;
  }

  &__item {
    display: inline;

    &:not(:last-child):after {
      content: "\2215";
      font-size: 2rem;
      line-height: 1.6rem;
      padding: 0 1.2rem;
      display: inline-block;
      vertical-align: middle;
      color: $color-grey;
    }
  }

  &__link {
    padding: .5rem 0;

    &.active {
      text-decoration: underline;
    }
  }
}
