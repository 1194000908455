.molecule-subscriptionteaser {
  margin-bottom: 4rem;

  &__link {
    color: $color-white;
    background-color: $color-main;
    text-decoration: none;
    padding: .8rem 4rem;
    font-size: $default-font-size;
    font-family: SourceSansPro-Bold,sans-serif;

    &:hover {
      color: $color-white;
      text-decoration: none;
      background-color: $color-secondary;
    }
  }

  &__imagecontainer {
    position: relative;
    padding-bottom: 56.2%;
    margin-bottom: 1.5rem;
  }

  &__image {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__headline {
    color: $color-main;
    font-family: SourceSansPro-Bold,sans-serif;
  }

  &__content {
    margin-bottom: 2rem;

    h2 {
      font-family: $default-font-bold;
      /* TODO: place in _globlal.scss ?? */
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: .5rem;
      position: relative;

      .om-font-icon {
        color: $color-secondary;
        font-size: 2.5rem;
        position: absolute;
        bottom: -.05rem;
        margin: 0;
      }
    }

  }
}
