.togglemenuicon {

  &__checkbox {
    display: none;
  }

  &__button {
    height: 3.4rem;
    width: 3.4rem;
    top: 6rem;
    right: 6rem;
    z-index: 2000;
    text-align: center;
    cursor: pointer;

    &:hover &__icon::before {
      top: -1rem;
    }

    &:hover &::after {
      top: 1rem;
    }

  }

  &__icon {
    position: relative;
    margin-top: 1.7rem;

    width: 3rem;
    height: 2px;
    background-color: $color-grey;
    display: inline-block;

    &::before, &::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all .2s;

      width: 3rem;
      height: 2px;
      background-color: $color-grey;
      display: inline-block;
    }

    &::before {
      top: -.8rem;
    }

    &::after {
      top: .8rem;
    }
  }

}

.togglemenuicon__checkbox:checked + .togglemenuicon__button .togglemenuicon__icon {
  background-color: transparent;
}

.togglemenuicon__checkbox:checked + .togglemenuicon__button .togglemenuicon__icon::before {
  top: 0;
  transform: rotate(135deg);
}

.togglemenuicon__checkbox:checked + .togglemenuicon__button .togglemenuicon__icon::after {
  top: 0;
  transform: rotate(-135deg);
}
