.real_mainnavigation {
  -ms-user-select: None;
  -moz-user-select: None;
  -webkit-user-select: None;
  user-select: None;

  .organism-mainnavigation__more {
    min-width: 0;
    max-width: 0;
    height: 3.58rem;
    flex-grow: 0;
    opacity: 0;
    z-index: -1;
    transition: .2s;
    margin-right: 0;
    margin-left: 0;

    &.show {
      opacity: 1;
      z-index: 0;
      min-width: 15px;
      max-width: 15px;
    }

    &.left {
      background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 110%);
      &.show {
        margin-right: -15px;
      }
    }

    &.right {
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 110%);
      &.show {
        margin-left: -15px;
      }
    }
  }

  .organism-mainnavigation {
    color: $color-text;
    font-size: 1.5rem;
    padding: 0;
    flex-grow: 1;

    white-space: nowrap;
    -webkit-overflow-scrolling: touch;

    .navbar-nav {
      overflow-x: auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .organism-mainnavigation__list {
      padding: 0;
      display: flex;
    }

    .organism-mainnavigation__item {
      flex-shrink: 0;
      transition-duration: 0.5s;
    }

    .organism-mainnavigation__link {
      &:visited {
        color: $color-white;
        text-decoration: none;
        padding: .5rem .8rem;
        display: block;
        cursor: pointer;
      }
    }

    .organism-mainnavigation__navbar {
      display: flex;
      flex-wrap: nowrap;
    }

    .organism-mainnavigation__list &__item &__submenu {
      visibility: hidden;
      opacity: 0;
      display: none;

      position: absolute;
      transition: all 0.5s ease;
      padding: 1.5rem 0;
      border: 1px solid rgba($color-black, .15);
      border-radius: .5rem;
      max-width: 30rem;
    }

    .atom-menuitem__link.dropdown-toggle::after {
      float: none;
      line-height: 1.4rem;
    }

    .organism-mainnavigation__rightbutton {
      width: 1.5rem;
      background-color: $color-main;

      &:after {
        content: "\276F";
        width: 1em;
        text-align: center;
        transition: all .35s;
        display: block;
        height: 3.5rem;
        padding-top: .5rem;
        color: $color-white;
      }
    }

    .organism-mainnavigation__smalltext {
      font-size: 1rem;
      padding: 0 1.5rem;
    }

    .organism-mainnavigation__header {
      font-size: 1.8rem;
      font-weight: 700;
      color: $color-main;
      padding: 1rem 1.5rem 0;
      margin: 1rem 0 .8rem;
    }

    .organism-mainnavigation__submenu &__header {
      border-top: 1px solid #e9ecef; // TODO: color variable
    }

    .organism-mainnavigation__chiplist {
      padding: 0 1.5rem;
      display: flex;
      flex-wrap: wrap;
    }

    .organism-mainnavigation__chip {

      &:hover {
        background: transparent !important;
      }
    }

    .organism-mainnavigation__chiplink {
      background: #EBEBEB;
      border-radius: .3rem;
      display: block;
      padding: .2rem .7rem !important;
      font-size: 1.2rem !important;
      margin: .3rem !important;
      border-bottom: 0 !important;

      &:hover {
        text-decoration: none;
      }
    }

    .organism-mainnavigation__chip:not(:last-child) &__chiplink {
      margin-right: .3rem !important;
    }

    .molecule-mainulevel__list__wrapper {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      width: 100%;
      background-color: $color-main;
      padding: 0;

      .molecule-mainulevel__list__wrapper {
        display: block;
      }

      .atom-menuitem__item {
        background-color: $color-main;

        &.dropdown {
          .dropdown-menu {
            visibility: visible;
            opacity: 1;
            display: none;
            z-index: 999;

            a {
              color: $color-text;
              cursor: pointer;
              padding: .5rem 1.5rem;
              margin: 0 1.5rem;
              border-top: 1px solid $color-light-grey2; // TODO: color variable
              border-bottom: 1px solid transparent;
            }

            li {
              background: $color-white;
              &:first-child a {
                border-top: 1px solid transparent;
              }
            }

            li:hover {
              background: $color-light-grey2;
              a {
                border-top: 1px solid transparent;
              }
            }
          }

          &:hover,
          &.show {
            .dropdown-menu {
              display: block;
            }
          }
        }

        .atom-menuitem__item {
          background-color: inherit;
        }
      }
    }

    .organism-mainnavigation__left-menuitems,
    .organism-mainnavigation__right-menuitems {
      flex-grow: 0;
      display: flex;

      .dropdown:hover,
      .dropdown.show {
        .dropdown-menu {
          display: block;
        }
      }

      .atom-menuitem__link {
        color: $color-white;
        cursor: pointer;
        text-decoration: none;

        background-color: $color-secondary;

        &.show {
          opacity: 1;
          display: block;
          visibility: visible;
          z-index: 999;
        }

        .atom-regiontag__link.active {
          color: #fff;
          background-color: #04a8fb;
          border-color: #04a8fb;
        }
        .atom-regiontag__link:focus {
          outline: none;
        }
      }

      .atom-menuitem__link:hover,
      .atom-menuitem__link:focus {
        background-color: $color-main;
      }
    }

    .organism-mainnavigation__right-menuitems {
      display: none;
    }

    .organism-mainnavigation__abo,
    .organism-mainnavigation__omplus {
      margin-left: 5px;
    }

    .organism-mainnavigation__myregion {
      margin-right: 5px;
    }

    .organism-mainnavigation__omplus {
      width: 100px;

      svg {
        fill: $color-white;
        height: 16px;
        margin-top: -1px;
        margin-bottom: 1px;
      }
    }
  }

  .molecule-myregionmenu {
    display: none;

    max-width: 36rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .4rem;
    padding-top: 1rem;
    position: absolute;
    left: 0;
    transition: all .5s ease;

    &.show {
      opacity: 1;
      display: block;
      visibility: visible;
      z-index: 999;
    }
  }

  .molecule-mainulevel__list {
    flex-grow: 1;
  }

  .split.button {
    padding: .5rem 2rem;
    display: block;
    color: $color-white;
    position: relative;
    min-width: 9rem;

    &:hover {
      background-color: $color-secondary;
      text-decoration: none;
    }
  }

  .split.button span {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 2.75rem;
  }

  .split.button span:after {
    content: "\276F";
    color: #fff;
    padding-left: .5rem;
    display: inline-block;
    font-size: 1.3rem;
    float: right;
    margin-left: 5px;
    transform: rotate(90deg);
    transition: all .35s;
  }
}
