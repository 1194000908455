.togglemenu {
  display: inline-block;
  z-index: 9999;

  &__wrapper {
    display: flex;
  }

  &__search {
    margin-right: 2rem;
    position: absolute;
    right: 4.5rem;
    top: 0;

    label {
      position: relative;
      transition: all 1s;
      width: 3.5rem;
      height: 3.5rem;
      background: #fff;
      box-sizing: border-box;
      border-radius: 2.5rem;
      border: 2px solid $color-main;
      display: block;
      margin: 0;
      overflow: hidden;

      .atom-logo {

        a {
          width: 30px;
          height: 30px;
          position: absolute;
          right: 0;
        }
      }
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 3rem;
      line-height: 3rem;
      outline: 0;
      border: 0;
      display: none;
      font-size: 1.5em;
      color: $color-main;
      border-radius: 2rem;
      padding: 0 2rem;
    }

    .fa {
      box-sizing: border-box;
      padding: 1rem;
      width: 3rem;
      height: 3rem;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 50%;
      color: red;
      text-align: center;
      font-size: 1.2em;
      transition: all 1s;
    }

    label:hover {
      width: 20rem;
      cursor: pointer;
    }

    label:hover input {
      display: block;
      padding-right: 3rem;
    }

    label:hover .fa {
      background: $color-main;
      color: $color-white;
    }

    svg {
      box-sizing: border-box;
      padding: .5rem;
      transition: all 1s;
      position: absolute;
      right: 0;
      width: 3rem !important;
    }
  }

  &__canvas {
    max-width: 83.33333333333334%;
    color: $color-white;
    background-color: $color-main;
    text-align: left;
    font-size: 1.5rem;

    position: fixed;
    z-index: 9999;
    height: 100%;
    width: 100%;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
    transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
    transition: transform 0.5s ease, opacity 0.5s ease;
    transition: transform 0.5s ease, opacity 0.5s ease, -webkit-transform 0.5s ease;
    top: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: translate(-100%, 0) translate3d(-100%, 0, 0);
    transform: translate(-100%, 0) translate3d(-100%, 0, 0);

    &::-webkit-scrollbar {
      display: none;
    }

    &[data-comes-from="top"] {
      top: 0;
      left: 0;
      right: 0;
      -webkit-transform: translate(0, -100%) translate3d(0, -100%, 0);
      transform: translate(0, -100%) translate3d(0, -100%, 0);
    }

    &[data-comes-from="bottom"] {
      bottom: 0;
      left: 0;
      right: 0;
      -webkit-transform: translate(0, 100%) translate3d(0, 100%, 0);
      transform: translate(0, 100%) translate3d(0, 100%, 0);
    }

    &[data-comes-from="right"] {
      top: 0;
      bottom: 0;
      right: 0;
      left: auto;
      -webkit-transform: translate(100%, 0) translate3d(100%, 0, 0);
      transform: translate(100%, 0) translate3d(100%, 0, 0);
    }

    .togglemenu__login {
      border-bottom: 1px solid rgba($color-white, .5);
      height: 4.5rem;

      &.logoutContainer.hide,
      &.accountContainer.hide {
        display: none;
      }

      &.logoutContainer.show,
      &.accountContainer.show {
        display: block;
      }

      .molecule-metalinks__link {
        display: flex;
        align-items: center;

        .om-font-icon {
          height: 4.5rem;

          &:before {
            padding-top: .3rem;
          }
        }

        span {
          padding-top: 0;
        }

        &:hover {

          .om-font-icon {
            color: $color-white;
          }
        }

        &.logout {
          display: none;
        }

        &.logout.show {
          display: flex;
        }
      }
    }

    .togglemenu__search {
      position: relative;
      top: 0;
      left: 0;
      padding: 1rem 2.3rem;
      width: 100%;

      .togglemenu__searchinput {
        font-size: $default-font-size;
        padding-right: 3.5rem;
        display: inline-block;

        &:focus {
          color: $color-main;
        }

        &:placeholder-shown {
          color: $color-grey;
        }

        &::placeholder {
          color: inherit;
        }
      }

      label {
        width: 100%;

        &:hover,
        &:focus {
          width: 100%;
        }
      }
    }

    .button.split {
      padding: 1rem 2rem;

      span {
        display: block;
        width: 5rem;

        &:after {
          right: 2.3rem;
        }
      }

      &:hover {

        background-color: $color-secondary;
      }
    }

    .organism-mainnavigation__abo {
      position: relative;
      padding-left: 0;
    }
  }

  &__helper {
    margin: 0;
    background-color: rgba(47, 45, 43, 0.3);

    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
    transition: opacity 0.5s ease, visibility 0.5s ease;
  }

  &__header {
    color: $color-secondary;
    margin-top: 1.5rem;
    padding: 1rem 2.3rem;
    border-bottom: 1px solid rgba($color-white, .5);
  }

  &__input[type="checkbox"] {
    position: absolute;
    top: 0;
    right: 0;
    height: 0;
    width: 0;
    opacity: 0;

    &:checked ~ .togglemenu__canvas {
      overflow-y: auto;
      overflow-x: hidden;
      opacity: 1;
      -webkit-transform: translate(0, 0) translate3d(0, 0, 0);
      transform: translate(0, 0) translate3d(0, 0, 0);
    }

    &:checked ~ .togglemenu__helper {
      opacity: 1;
      visibility: visible;
    }

    &:focus ~ .togglemenu__canvas,
    &:active ~ .togglemenu__canvas {
      -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
      transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
      transition: transform 0.5s ease, opacity 0.5s ease;
      transition: transform 0.5s ease, opacity 0.5s ease, -webkit-transform 0.5s ease;
    }
  }

  &__input {
    &:focus ~ &__helper,
    &:active ~ &__helper {
      -webkit-transition: opacity 0.5s ease, visibility 0.5s ease;
      transition: opacity 0.5s ease, visibility 0.5s ease;
    }

  }

  .icon {
    color: #fff;
  }

  /* Metalinks Menu */
  .molecule-metalinks {
    text-align: inherit;
    font-size: 1.5rem;
    padding: 0;
    display: block;

    &__list {
      height: auto;
      display: block;
      margin: 0;
    }

    &__item {
      display: block;
      margin: 0;
      padding: .5rem 2.3rem;
      border-bottom: 1px solid hsla(0, 0%, 100%, .5);

      &:hover {
        background-color: $color-secondary;
      }

      .atom-logo {
        svg {
          fill: $color-white;
        }
      }
    }

    &__link {
      color: inherit;
      padding: 0;
      height: auto;

      &:hover {
        background-color: $color-secondary;
        text-decoration: none;
      }

      i {
        height: 4rem;
        width: 4rem;
        font-size: 4rem;

        &::before {
          padding-top: .5rem;
          display: block;
        }
      }

      span {
        display: block !important;
        padding-top: .5rem;
      }
    }

    &__search {
      display: none;

      .molecule-metalinks__item {
        padding: .5rem 1rem;
      }

    }

  }

  .molecule-metalinks__linkicon {
    width: 40px;
    height: 45px;
    margin: 0 .8rem;
    padding: .6rem;
    text-align: center;

    svg {
      height: 30px;
      width: 20px;
      fill: $color-white !important;
    }
  }

  .molecule-metalinks__sociallinks {
    display: block !important;
    margin: 0;
    height: auto;

    .om-font-icon {
      font-size: 4rem;
      width: auto;
      color: $color-white;
    }
  }

  .molecule-sociallinks {
    margin: 0;
    padding: .5rem 1rem;
    height: auto;
    border-bottom: 1px solid rgba($color-white, .5);

    &__link {
      height: 4rem;
      width: 4rem;
    }
  }

  &__mobile {
    display: flex;

  }
}

.togglemenuicon {

  &__checkbox {
    display: none;
  }

  &__button {
    height: 3.4rem;
    width: 3.4rem;
    z-index: 2000;
    cursor: pointer;
    position: absolute;
    right: 1.5rem;
    top: 0;

    &:hover &__icon::before {
      top: -1rem;
    }

    &:hover &::after {
      top: 1rem;
    }

  }

  &__icon {
    position: relative;
    margin-top: 1.7rem;

    width: 3rem;
    height: 2px;
    background-color: $color-main;
    display: inline-block;

    &::before, &::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all .2s;

      width: 3rem;
      height: 2px;
      background-color: $color-main;
      display: inline-block;
    }

    &::before {
      top: -.8rem;
    }

    &::after {
      top: .8rem;
    }
  }
}

.togglemenuicon__checkbox:checked + .togglemenuicon__button .togglemenuicon__icon {
  background-color: transparent;
}

.togglemenuicon__checkbox:checked + .togglemenuicon__button .togglemenuicon__icon::before {
  top: 0;
  background-color: $color-main;
  transform: rotate(135deg);
}

.togglemenuicon__checkbox:checked + .togglemenuicon__button .togglemenuicon__icon::after {
  top: 0;
  background-color: $color-main;
  transform: rotate(-135deg);
}

/* i dont know why here are so much styles but i have no time an overwrite them only */

.real_burgermenu {
  -ms-user-select: None;
  -moz-user-select: None;
  -webkit-user-select: None;
  user-select: None;

  .organism-mainnavigation__left-menuitems,
  .organism-mainnavigation__right-menuitems {
    border-top: 3px solid rgba($color-white, .5);
    border-bottom: 3px solid rgba($color-white, .5);
  }

  .organism-mainnavigation__omplus svg {
    fill: $color-white;
    height: 16px;
    width: 5.6rem;
  }

  .molecule-myregionmenu.dropdown-menu {
    display: none;
  }

  .atom-menuitem__item:hover > .atom-menuitem__dropdown{ /* todo: mäusemelken */
    display:none;
  }

  .atom-menuitem__item.show:hover > .atom-menuitem__dropdown{
    display:block;
  }

  .organism-mainnavigation__navbar,
  .atom-menuitem__item.show .dropdown-menu {
    visibility: visible;
    opacity: 1;
    display: block;
    z-index: 9;
  }

  .accordion,
  .atom-menuitem__link {
    cursor: pointer;
    padding: 1rem 2rem;
    width: 100%;
    text-align: left;
    outline: none;
    transition: 0.4s;
    position: relative;
    background-color: inherit;
    color: $color-white;
    border: none;
    border-bottom: 1px solid rgba($color-white, .5);

    &:hover,
    &.show {
      color: $color-white;
      background-color: $color-secondary;
      outline: none;
    }
  }

  .accordion:after,
  .atom-menuitem__link.dropdown-toggle:after {
    content: '\276F';
    font-size: 13px;
    color: $color-white;
    float: right;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    transition: all .35s;
    position: absolute;
    right: 2.5rem;
    top: 1.2rem;

    &.show:after {
      content: "\276F";
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }

  .organism-mainnavigation,
  .molecule-mainulevel__list__wrapper,
  .footer-links,
  .molecule-link-list,
  .molecule-myregiontaglist {
    margin: 0;
    padding: 0;
  }

  .molecule-myregiontaglist__taglist,
  .my-region-tab-navigation__button,
  .my-region-tab-navigation__linkcontainer {
    padding-left: 10px;
    padding-right: 10px;
  }

  .molecule-myregiontaglist__header {
    padding-left: 12px;
    margin-left: 0;
    margin-bottom: 0;
  }

  .my-region-tab-navigation__linkcontainer {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .panel .molecule-link-list,
  .atom-menuitem__dropdown.dropdown-menu .molecule-mainulevel__list__wrapper,
  .molecule-myregionmenu.dropdown-menu .molecule-myregionmenu__submenu {
    border-radius: .4rem;
    overflow:hidden;
    background-color: $color-white;
    display: block;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    margin:0;
    max-width: 30rem;
  }

  .panel,
  .atom-menuitem__dropdown.dropdown-menu,
  .molecule-myregionmenu.dropdown-menu {
    max-height: 0;
    max-width:none;
    padding:0;
    margin:0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    position: initial;
    visibility: visible;
    opacity: 0;

    &.show {
      display:block;
      opacity: 1;
      padding: 10px;
      border-bottom: 1px solid rgba($color-white, .5);
      background-color: $color-secondary;
    }
  }

  .panel,
  .atom-menuitem__dropdown.dropdown-menu {
    .molecule-link-list-headline {
      display: none;
    }

    li {
      display:block;
      width:auto;
      margin-left:10px;
      margin-right:10px;
      transition: .4s;

      &:last-child,
      &:last-child a {
        border-bottom: none!important;
      }

      &:hover {
        background-color: $color-light-grey2;
      }
    }
  }

  .atom-link,
  .atom-menuitem__dropdown .atom-menuitem__link {
    display: block;
    text-decoration: none;
    color: $color-text;
    padding: .5rem 1.5rem!important;
    margin: 0!important;
    border-bottom: 1px solid #e9ecef!important;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }
  .organism-mainnavigation__omplus, .organism-mainnavigation__abo {
    background-color: #fff;
    .atom-menuitem__link, svg{
      color: $color-main;
      fill: $color-main;
    }
    &:hover {
      .atom-menuitem__link, svg {
        color: #fff;
        fill: #fff;
      }
    }
  }
  .organism-mainnavigation__omplus {
    border-bottom: 1px solid $color-main!important;
  }
}
