@include media-breakpoint-up(md) {
  .organism-lead {

    .row.container__row {

      .col-md-4.teaser1,
      .col-md-4.teaser2 {
        margin-bottom: 0;
      }

      &.teaser_wide {

        .molecule-teaser__content {

          padding-top: 1rem;
        }
      }
    }
  }
}
