@include media-breakpoint-up(md) {
  .atom-scrolltotop {
    position: fixed;
    bottom: 20px;
    right: 0;
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    margin: 30rem 1em 0 0;
    border-radius: 50%;
    padding: 0.25em;
    width: 50px;
    height: 50px;
    background-color: #04a8fb;
    border: 1px solid rgba(255, 255, 255, .4);

    &:after {
      content: "\276F";
      transform: rotate(-90deg);
      color: #fff;
      font-size: 27px;
    }

    &.show {
      visibility: visible;
      opacity: 1;
      color: transparent !important;
    }

    &.hide {
      visibility: hidden;
      opacity: 0;
    }

    .screen-reader-text {
      position: absolute;
      clip-path: inset(50%);
      margin: -1px;
      border: 0;
      padding: 0;
      width: 1px;
      height: 1px;
      overflow: hidden;
      word-wrap: normal !important;
      clip: rect(1px, 1px, 1px, 1px);

      &:focus {
        display: block;
        top: 5px;
        left: 5px;
        z-index: 100000;
        clip-path: none;
        background-color: #eee;
        padding: 15px 23px 14px;
        width: auto;
        height: auto;
        text-decoration: none;
        line-height: normal;
        color: #444;
        font-size: 1em;
        clip: auto !important;
      }
    }
  }
}
