.atom-regiontag__link {
  background: $color-light-grey2;
  border-radius: 2rem;
  display: block;
  padding: .3rem 1rem!important;
  font-size: 1.2rem!important;
  margin: 0 .5rem .5rem 0!important;
  color: #52525E;
  border: 2px solid #efefef;
  font-family: $default-font-bold;
  white-space: nowrap;

  &:hover {
    color: #fff;
    background-color: rgba($color-secondary,0.8);
    text-decoration: none;
    border-color: transparent;
  }

  &:not(:last-child) &__chiplink {
    margin-right: .3rem !important;
  }

  &.active,&.active:hover{
    color: $color-white;
    background-color: $color-secondary;
    outline: none;
  }
}


/*

  todo: found somewhere lol

 .atom-regiontag__link {
        background-color: #ebebeb;

        &.active {
          background-color: $color-secondary;
          border-color: $color-secondary;
          color: $color-white;
        }

        &:hover {
          background-color: $color-secondary;
          outline: none;
        }
      }

 */
