$divider-space: 20, 40, 60, 80, 120;
$divider-bp: 'sm','md','lg','xl';

.atom-divider {
    display: block;
    width: 100%;

    @each $space in $divider-space {
        &.spacing-#{$space}-xs {
            height: #{$space}px;
        }
    }

    @each $bp in $divider-bp {
        @media (min-width: map-get($grid-breakpoints,$bp)) {
            @each $space in $divider-space {
                &.spacing-#{$space}-#{$bp} {
                    height: #{$space}px;
                }
            }
        }
    }
}

.neos-backend {
    .atom-divider {
        background-color: #ffdddd;
    }
}
