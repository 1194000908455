.atom-logo {
  a,span {
    height: 100%;
    width: 100%;
    display: block;
    svg {
      width: 100%;
      height: 100%;
      fill: $color-main;
    }
  }
}
