@include media-breakpoint-up(md) {
  .molecule-teaseroffer {

    &__content {
      display: flex; // TODO: not mobile
    }

    &__list {
      padding: 0 2rem;
      flex: 1;
    }

    &__boxheading {
      display: none;
    }

    &__link {
      min-width: 18rem;
      width: inherit;
    }

    &__price-after-testmonth {
      text-align: left;
    }

    &__listcontainer {
      align-items: flex-end; // TODO: not mobile
      flex-direction: row-reverse; // TODO: not mobile
      display: flex; // TODO: not mobile
    }

    &__heading {
      display: flex;  // TODO: not mobile
    }

    &__abo {
      padding: 0 2rem;
      margin-top: 3rem;
    }

    &__linksmobile {
      display: none;
    }

    &__linkstablet {
      display: block;
    }

    &__footerlogos {
      display: none;
    }

  }
}
