@include media-breakpoint-up(md) {
    .organism-header {

        &__logo {
            max-width: 35rem;
            width: 100%;

            a {
                margin-top: 15px;

                svg {
                    height: 7.5rem;
                }
            }
        }

        &__omplus {
            margin: 3rem 10px 0 -60px;
            padding-right: 0;
        }

        &__social-omplus-wrapper {
            padding-right: 0px;
        }

        &__description {
            font-size: 1.8rem;
            padding: 3.5rem 0;
            max-width: 18rem;
        }

        &__weathercontainer {
            font-size: 1.6rem;
        }
        &__omplus {
            margin: 0.8rem 0 0;
        }
    }
}