.molecule-teaser {
  margin-bottom: 1.5rem;

  &__link {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
      text-decoration: inherit;
    }
  }

  &__imagecontainer {
    position: relative;
    padding-bottom: 56.2%;
  }

  &__image {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__omplus {

    .atom-logo {
      margin-right: .7rem;

      svg {
        width: 5rem;
        height: 1.5rem;
        float: left;
        fill: $color-secondary;
      }
    }
  }

  &__content {
    padding: 0 1rem 1rem;

    h2 {
      font-family: $default-font-bold;
      /* TODO: place in _globlal.scss ?? */
      font-size: 2.4rem;
      margin-bottom: 1.5rem;
    }

    h3 {
      color: $color-main;
      display: flex;
      font-weight: 600;
      /* TODO: place in _globlal.scss ?? */
      font-size: $default-font-size;
      line-height: 1.4rem;
    }

    p {
      margin-bottom: .5rem;
      position: relative;

      .om-font-icon {
        color: $color-secondary;
        font-size: 2.5rem;
        position: absolute;
        bottom: -.05rem;
        margin: 0;
      }
    }

  }

  &__date {
    color: $color-date;
    font-size: 1.4rem;
  }

  &:hover {
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    transition: all .3s ease;
  }

}