@include media-breakpoint-up(md) {
  .molecule-metalinks {

    &__item {
      display: inline-block;

      &:not(:first-child) {
        margin-left: 3rem;
      }
    }

    &__search {

      .molecule-metalinks__link {

        i {
          font-size: 2.5rem;
        }
      }

      .molecule-metalinks__item.search-box {
        position: relative;
        min-width: 7.5rem;
      }
    }

    &__ePaper {
      display: block;
      margin-left: 4rem;
    }

  }

  .molecule-metalinks-ad__ads {
    position: relative;
    top: 0;
  }
}
