$color-array-grid:
        "black" #000,
        "white" #fff
!default;

.atom-column {
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.diu-grid-alignment-center {
  align-self: center;
  display: flex;
  flex-direction: column;
}

.diu-grid-alignment-bottom {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
}

@each $colorClass, $colorVar in $color-array-grid {
    .atom-column.bg-color-#{$colorClass} {
        background-color: $colorVar;
    }
}

@media screen and (min-width: 540px) {
    .atom-column {
        margin-bottom: 0;
    }
}
