@include media-breakpoint-up(md) {
  .real_mainnavigation{
    .organism-mainnavigation {

      .dropdoqn-menu {
        min-width: 300px;
        right: 0;
      }

      .molecule-mainulevel__list>.atom-menuitem__item:last-child {
        margin-right: initial;
      }

      .organism-mainnavigation__rightbutton{
        display:none;
      }
    }
  }
}
