@include media-breakpoint-up(lg) {
  .molecule-sociallinks {
    height: 3rem;
    margin: 0;

    .om-font-icon {
      color: rgba($color-grey, .8);
      font-size: 3rem;
    }

    &__link {
      line-height: 3rem;

      &:hover {
        text-decoration: none;

        .om-font-icon {
          color: $color-secondary;
        }
      }
    }


  }
}