.molecule-toparticles {
  font-size: $default-font-size;

  &__list {
    list-style-position: inside;
    list-style: none;
    counter-reset: my-counter;
    padding: 0;
    border-top: 1px solid $color-main;
    border-bottom: 1px solid $color-main;
  }

  &__item {
    display: flex;
    align-items: center;
    counter-increment: my-counter;
    &:before{
      display: inline-flex;
      justify-content: center;
      content: counter(my-counter);
      border-radius: 50%;
      border: 1px solid $color-secondary;
      color: $color-secondary;
      font-size: $default-font-size;
      font-weight: normal;
      min-width: 3rem;
      height: 3rem;
      margin: .75rem;
    }
    padding: .3rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid $color-main;
    }

    &:hover {
      background-color: $color-secondary;
      &:before{
        color: $color-white;
        border-color: $color-white;
      }

      a {
        color: $color-white;
        text-decoration: none;
      }

      .molecule-toparticles__index {
        color: $color-secondary;
        background-color: $color-white;
      }

      .atom-logo svg {
        width: 5rem;
        height: 1.5rem;
        float: left;
        fill: $color-white;
        margin-right: 1rem;
      }
    }
    .hide-omplus .molecule-teaser__omplus {
      display: none;
    }
  }

  &__link {
    display: flex;
    color: $color-text;
    font-size: 1.4rem;
    font-weight: 600;
  }

  &__imagecontainer {
    display: none;
    height: 4.5rem;
    width: 8rem;
  }

  &__image {
    width: 100%;
    position: relative;
    padding-bottom: 56.2%;

    img {
      position: absolute;
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__title-container {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 0 1rem;
    line-height: normal;

    .atom-logo svg {
      width: 5rem;
      height: 1.5rem;
      float: left;
      fill: $color-secondary;
      margin-right: .5rem;
    }
  }
}

.molecule-toparticlesgroup__paid {
  .molecule-toparticles {
    &__list {
      counter-reset: my-counter 5;
    }
  }
}