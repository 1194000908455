@include media-breakpoint-up(md) {
  .molecule-paywall {
    &__img {
      display: block;
      background: url('../Images/paywall-2024-02-b.webp');
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      background-position: 46%;
    }

    &__content {
      padding: 2rem 4rem;
    }

    &__abo {
      padding: 0 15%;
    }
  }
}