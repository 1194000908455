@include media-breakpoint-up(md) {
  .molecule-article {

    &__content {

      h1 {
        font-size: 4rem;
      }

    }

  }
}