@font-face {
    font-family: 'SourceSansPro-Regular';
    src: local('SourceSansPro-Regular'),
        url("../Fonts/SourceSansPro-Regular.woff2") format("woff2"),
        url("../Fonts/SourceSansPro-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro-Light';
    src: local('SourceSansPro-Light'),
        url("../Fonts/SourceSansPro-Light.woff2") format("woff2"),
        url("../Fonts/SourceSansPro-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro-SemiBold';
    src: local('SourceSansPro-SemiBold'),
        url("../Fonts/SourceSansPro-SemiBold.woff2") format("woff2"),
        url("../Fonts/SourceSansPro-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro-Bold';
    src: local('SourceSansPro-Bold'),
        url("../Fonts/SourceSansPro-SemiBold.woff2") format("woff2"),
        url("../Fonts/SourceSansPro-SemiBold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'OMIconFont';
    src: local('OMIconFont'),
        url("../Fonts/OMIconFont.woff2") format("woff2"),
        url("../Fonts/OMIconFont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome 6 Brands';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url('../Fonts/fa-brands-400.woff2') format('woff2'),
    url('../Fonts/fa-brands-400.ttf') format('truetype');
}