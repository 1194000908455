@include media-breakpoint-up(lg) {
  .real_mainnavigation{
    .organism-mainnavigation {
      padding: 0;

      .dropdown-menu.show,
      .button.split + .dropdown-menu.show {
        opacity: 1;
        display: block;
        visibility: visible;
        z-index: 999;
      }

      .organism-mainnavigation__navbar {
        display:flex;
        flex-wrap: nowrap;

        .molecule-myregionmenu__accordion {
          display: none;
        }

        .molecule-myregionmenu__smalltext {
          white-space: normal;
        }
      }

      .split.button span {
        display: block;
      }

      .organism-mainnavigation__right-menuitems{
        display:flex;
      }

      .overview-menu{
        display:none;
      }
    }
  }
}
