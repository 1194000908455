@include media-breakpoint-up(md) {
  .molecule-toparticlesgroup {

    &__top {
      padding-right: .5rem;

      .molecule-toparticles__list {
        margin-bottom: 1rem;
      }
    }

    &__paid {
      display: block;
      padding-left: .5rem;
      .h2 {
        padding-top: 0rem;
      }
    }
  }
}
