.row.article-list-row {
    .teaser-title {
        font-size: 1.6em;
    }

    .teaser-context {
        font-size: 1.4em;
    }

    .molecule-teaser__omplus .atom-logo svg {
        float: none;
        margin-bottom: 2px;
    }
}

.row:not(:last-child).article-list-row {
    border-bottom: 1px dotted $color-main;
}

a.row.article-list-row:hover {
    background: $color-secondary;
    cursor: pointer;
    text-decoration: none;
    color: $color-white!important;
    .atom-logo svg {
        fill: $color-white;
    }
}
