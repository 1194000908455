.spacing-top-10 {
    margin-top: 10px;
}

.spacing-top-20 {
    margin-top: 20px;
}

.spacing-top-30 {
    margin-top: 30px;
}

.spacing-top-40 {
    margin-top: 40px;
}

.spacing-top-50 {
    margin-top: 50px;
}

.spacing-top-60 {
    margin-top: 60px;
}

.spacing-bottom-10 {
    margin-bottom: 10px;
}

.spacing-bottom-20 {
    margin-bottom: 20px;
}

.spacing-bottom-30 {
    margin-bottom: 30px;
}

.spacing-bottom-40 {
    margin-bottom: 40px;
}

.spacing-bottom-50 {
    margin-bottom: 50px;
}

.spacing-bottom-60 {
    margin-bottom: 60px;
}


@media(min-width: 764px){
    .spacing-top-10-tablet {
        margin-top: 10px;
    }

    .spacing-top-20-tablet {
        margin-top: 20px;
    }

    .spacing-top-30-tablet{
        margin-top: 30px;
    }

    .spacing-top-40-tablet {
        margin-top: 40px;
    }

    .spacing-top-50-tablet {
        margin-top: 50px;
    }

    .spacing-top-60-tablet {
        margin-top: 60px;
    }

    .spacing-bottom-10-tablet {
        margin-bottom: 10px;
    }

    .spacing-bottom-20-tablet {
        margin-bottom: 20px;
    }

    .spacing-bottom-30-tablet {
        margin-bottom: 30px;
    }

    .spacing-bottom-40-tablet {
        margin-bottom: 40px;
    }

    .spacing-bottom-50-tablet {
        margin-bottom: 50px;
    }

    .spacing-bottom-60-tablet {
        margin-bottom: 60px;
    }
}

@media(min-width: 1170px){
    .spacing-top-10-desktop {
        margin-top: 10px;
    }

    .spacing-top-20-desktop {
        margin-top: 20px;
    }

    .spacing-top-30-desktop {
        margin-top: 30px;
    }

    .spacing-top-40-desktop {
        margin-top: 40px;
    }

    .spacing-top-50-desktop {
        margin-top: 50px;
    }

    .spacing-top-60-desktop {
        margin-top: 60px;
    }

    .spacing-bottom-10-desktop {
        margin-bottom: 10px;
    }

    .spacing-bottom-20-desktop {
        margin-bottom: 20px;
    }

    .spacing-bottom-30-desktop {
        margin-bottom: 30px;
    }

    .spacing-bottom-40-desktop {
        margin-bottom: 40px;
    }

    .spacing-bottom-50-desktop {
        margin-bottom: 50px;
    }

    .spacing-bottom-60-desktop {
        margin-bottom: 60px;
    }
}

