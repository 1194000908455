.organism-header {

  svg {
    overflow: visible;
  }

  .style__paper {
    svg {
      height: 17px;
    }
  }
  .style__om-stellen {
    svg {
      padding-right: 7px;
    }
  }

  &__metanavigation {
    text-align: right;
  }

  &__logosection {
    display: flex;
  }

  &__logocontainer {
    display: flex;
  }

  &__logo {
    max-width: 21rem;
    width: 125%;

    a {

      svg {
        height: 7.5rem;
      }
    }
  }

  &__omplus {
    height: 22px;
    width: 105px;
    margin: 9px 30px 0 -60px;
    float: left;
    margin-left: -60px;
    a {
      background-color: #FF0000;
      border-radius: 2px;
      display: inline-block;
      color: #fff;
      &:hover {
        color: #fff;
        background-color: #FF0000;
      }
    }
    .atom-menuitem__link {
      display: flex;
      color: #fff;
      text-decoration: none;
      height: 100%;
      padding: 0;
      .atom-logo.style__om-plus-short {
        height: 100%;
        display: inline-block;
        svg {
          width: 37px;
          height: 16px;
          fill: #fff;
          margin: 3px;
        }
      }
    }
    .organism-header__omplus-abo {
      font-size: 12px;
      align-self: center;
    }
  }

  &__social-omplus-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 90px;
  }

  &__description {
    max-width: 12rem;
    padding-bottom: 1rem;
    font-size: 1.2rem;
    line-height: normal;
    color: $color-main;
  }

  &__weather {
    flex-grow: 1;
    height: 12rem;
    text-align: right;
    color: $color-main;
    padding-top: 4rem;
  }

  &__weathercontainer {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    padding-bottom: 1rem;
    font-size: 1.1rem;
  }

  &__date {
    color: $color-text;
  }

  &__toptopics {
    .molecule-toptopics__item {
      &:not(:last-child):after {
        content: "\00b7";
      }
    }
  }

  .atom-logo {
    &.style__om-hilft {
      width: 90px;
    }
  }

}