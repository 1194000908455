/* SLIDER */
.swiper-container {
  height: 500px;
  background-color: #f7f7f7;
  margin-bottom: 20px;

  .swiper-wrapper {
    text-align: center;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
    width: 100% !important;

    img {
      object-fit: contain;
      height: 500px;
    }
  }

  .swiper-slide img.swiper-lazy {
    margin-bottom: 5%;
  }

  .source {
    font-size: 13px;
  }

  .caption {
    font-size: 14px;
    line-height: 20px;
    background-color: #fff;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 20px;
  }

  .swiper-pagination {
    text-align: right;
    bottom: 0;
    background-color: #fff;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 14px;
    line-height: 16px;
    color: #999;
  }

  .swiper-button-next.swiper-button-white,
  .swiper-button-prev.swiper-button-white {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #9b9b9b;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    outline: none;
    top: 45%;
  }

  .swiper-button-next.swiper-button-white:hover,
  .swiper-button-prev.swiper-button-white:hover {
    background-color: #04a8fb;
  }

  .swiper-lazy-preloader-white {
    --swiper-preloader-color: #04a8fb;
    top: 45%;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 20px;
  }
}
