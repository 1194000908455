.template-author {
  &__image-container {
    display: flex;
    justify-content: center;
  }

  &__image {
    max-height: 220px;
    max-width: 220px;
    border-radius: 50%;
  }

  &__info-container {
    &__profile {
      margin-top: 50px;
      font: 16px/20px $default-font-semibold;
      color: $color-main;
    }
    &__name {
      font: 30px/30px $default-font-bold;
      color: $color-main;
    }
    &__biography {
      margin-top: 10px;
      font: 16px/28px $default-font-regular;
    }
  }
  &__articles {
    margin: 1.5rem 0;
    color: $color-text;
    font: 24px/29px $default-font-bold;
    border-bottom: 1px solid $color-main;
    padding-bottom: 11px;
  }



  &__form {

    .row {
      margin-bottom: 36px;
    }

    ul.errors {
      color: #ff0000;
      font-size: 12px;
      padding-left: 0;
    }

    .template-author__form__slidedown {
      height: 0px;
      overflow: hidden;
      transition: height 0.25s ease-out;
    }

    &__intro-text {
      margin-top: 46px;
      color: $black;
      &__title {
        font-size: 24px;
        line-height: 29px;
        font-family: $default-font-semibold;
      }
      &__subtitle {
        font-size: 16px;
        line-height: 20px;
        font-family: $default-font-semibold;
      }
    }

    &__label {
      margin-top: 36px;
      color: $black;
      font-size: 14px;
      line-height: 28px;
      font-family: $default-font-semibold;
    }

    &__submit {
      background-color: $color-main;
      height: 52px;
      color: $color-white;
      font: normal 16px/20px $default-font-semibold;
      border: none;
    }

    &__testmodus {
      font-size: 20px;
      font-weight: bold;
      color: #ff9933;
      margin-top: -30px;
    }

    &__agb {
      margin-top: 26px;
      display: flex;
      flex-direction: column;
      input[type=checkbox] {
        position: absolute;
      }
      span {
        margin-top: -25px;
        font: normal 14px/20px $default-font-regular;
        margin-left: 30px;
        display: block;
      }
    }

    &__more-contact {
      line-height: 20px;
      font-size: 16px;
      margin-top: 13px;
      &__thanks {
        padding-top: 40px;
        padding-bottom: 40px;
        color: $color-main;
      }
    }

    &__required-fields {
      line-height: 18px;
      font-size: 14px;
      color: $color-text-input;
    }

    #toggle-author-contact-form {
      display: none;
      & + label {
        width: 100%;
        padding-top: 36px;
        padding-bottom: 9px;
        font: 16px/28px $default-font-regular;
        color: $color-main;
        border-bottom: 1px solid $color-main;
        svg {
          &:not(first-of-type) {
            float: right;
            height: 14px;
            width: 14px;
            margin-right: 15px;
            margin-left: 18px;
            margin-top: 7px;
          }
          &:last-of-type {
            display: none;
          }
          &:first-of-type {
            float: left;
            height: 16px;
            width: 16px;
            margin-right: 15px;
            margin-left: 18px;
            margin-top: 7px;
          }
        }
      }
      &:checked {
        & + label svg {
          &:last-of-type {
            display: block;
          }
          &:nth-last-of-type(2) {
            display: none;
          }
        }
        &~ .template-author__form__slidedown {
          height: 100%;
          transition: height 0.25s ease-in;
        }
      }
    }
    input[type=text] {
      width: 100%;
      height: 49px;
      border: 1px solid #ADADAD;
      border-radius: 0;
      font-size: 16px;
      line-height: 28px;
      padding-left: 22px;
      color: $color-text;
      &::placeholder {
        color: $color-text-input;
      }
    }
    textarea {
      width: 100%;
      height: 151px;
      border: 1px solid #ADADAD;
      border-radius: 0;
      font-size: 16px;
      line-height: 28px;
      padding-left: 22px;
      padding-top: 22px;
      color: $color-text;
      &::placeholder {
        color: $color-text-input;
      }
    }
    input[type=checkbox] {
      height: 21px;
      width: 21px;
      border-radius: 0;
      border: 1px solid #ADADAD;
    }
    
  }
}