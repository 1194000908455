.om-font-icon {
  padding: 0;
  color: $color-white;
  opacity: 1;

  /* Facebook filled */
  &.icon-200302_iconfont-13:before {
    content: '\e808';
  }

  /* Instagram filled */
  &.icon-200302_iconfont-14:before {
    content: '\e80c';
  }

  /* Twitter filled */
  &.icon-200302_iconfont-15 {
    @include fa-icon-brands($fa-var-x-twitter);
    display: inline-block;
    height: 30px;
    width: 30px;
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    line-height: 1;
    margin-bottom: -1px!important;
    &:before {
      font-size: 1.6rem;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0.7rem;
      color: $color-main;
      line-height: 1;
    }
  }

  /* Youtube filled */
  &.icon-200302_iconfont-16:before {
    content: '\e804';
  }

  /* WhatsApp filled */
  &.icon-200302_iconfont-17:before {
    content: '\e810';
  }

  /* WhatsApp */
  &.icon-200302_iconfont-05:before {
    content: '\e80a';
  }

  /* Facebook */
  &.icon-200302_iconfont-01:before {
    content: '\e800';
  }

  /* Instagram */
  &.icon-200302_iconfont-02:before {
    content: '\e801';
  }

  /* Twitter */
  &.icon-200302_iconfont-03 {
    position: relative;
    @include fa-icon-brands($fa-var-x-twitter);
  }



  /* Youtube */
  &.icon-200302_iconfont-04:before {
    content: '\e80f';
  }

  /* User */
  &.icon-200302_iconfont-07:before {
    content: '\e805';
  }

  /* Envelope */
  &.icon-200302_iconfont-12:before {
    content: '\e80d';
  }



  /* Link */
  &.icon-200331_iconfont-32:before {
    content: '\e814';
  }

  /* Check mark */
  &.icon-200331_iconfont-35:before {
    content: '\e81c';
  }

  /* Arrow Up */
  &.icon-200331_iconfont-37:before {
    content: '\e815';
  }

  /* Arrow Down */
  &.icon-200331_iconfont-36:before {
    content: '\e816';
  }

  /* Double Angle Quotation Mark Right */
  &.icon-200331_iconfont-34:before {
    content: '\e81e';
  }
}

.molecule-sharearticle .om-font-icon {
  &.icon-200302_iconfont-03:before {
    font-size: 2.5rem;
    position: absolute;
    bottom: -13px;
    left: 4px;
  }
}

.molecule-sociallinks .om-font-icon {
  &.icon-200302_iconfont-03:before {
    font-size: 2.3rem;
    left: 11px;
    position: absolute;
    bottom: 2px;
  }
}