@include media-breakpoint-up(lg) {

  .organism-footer {
    font-size: $default-font-size;

    .margin-top {
      margin-top: 4rem;
    }

    .style {
      &__mtLogo,
      &__ovLogo {
        display: inline-flex;
        margin-right: 5rem;
      }
      &__iconSpotify {
        position: absolute;
        height: 100%;
      }
    }

    .social-media-links {
      display: block;
    }
  }
}
