.molecule-sociallinks {
  height: 3rem;
  margin: 2rem 0 1rem;

  .om-font-icon {
    color: rgba($color-grey, .8);
    margin: 0;
    font-size: 4rem;
    width: 3rem;
  }

  &__link {
    color: $color-grey;
    display: inline-block;
    line-height: 3.5rem;
    height: 3.5rem;
    width: 3rem;

    &:hover {
      text-decoration: none;

      .om-font-icon {
        color: $color-secondary;
      }
    }
  }


}