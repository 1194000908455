.organism-highlight {
  margin: 0 -1.5rem;
  padding: 1.5rem;
  background-color: #fafafa;

  &__teaserwide {

    .molecule-teaser {
      height: auto;
    }

    .molecule-teaser__imagecontainer {
      margin-bottom: 0;
    }

    .molecule-teaser__content {
      margin-top: 1.5rem;
    }
  }
}
