@include media-breakpoint-up(lg) {
  .template-selfservice {
    display: flex;
    nav {
      display: flex;
      flex-direction: column;
      border-right: 1px solid $color-light-grey;
      border-bottom: none;
      text-align: center;

      label {
        float: left;
      }
    }
  }
}