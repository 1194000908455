html {
    font-size: 10px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

body {
    font-family: $default-font-regular;
    font-weight: 400;
    line-height: 1.7;
    color: $color-text;
    background-color: $color-light-grey;
    &.cpauthenticated {
        .symplr-ad-holder, .molecule-adcontainer {
            display: none !important;
        }
    }
}

main {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: SourceSansPro-Bold,sans-serif;
        margin-bottom: 1rem;
    }

    ol,
    ul {
        list-style: none;
        font-size: $default-font-size;
    }
}

img {
    max-width: 100%;
    height: auto;
}

a:focus,
a:hover {
    color: $color-secondary;
}

p {
    font-size: $default-font-size;
}

.font-light {
    font-family: $default-font-light;
}

.font-regular {
    font-family: $default-font-regular;
}

.font-semibold {
    font-family: $default-font-semibold;
}

.font-bold {
    font-family: $default-font-bold;
}

.color-main {
    color: $color-main;
}

.color-secondary {
    color: $color-secondary;
}

.color-grey {
    color: $color-grey;
}

.container {
    background-color: $color-white;

    &__row {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

.om-font-icon {
    font-family: $default-font-icon;
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    /* font-size: 120%; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

main {
    padding: 2.5rem 0;
}

.category-header {
    margin: 1.5rem 0;
    border-bottom: 1px solid $color-main;
    &.category-header__link {
        display: flex;
        justify-content: space-between;
        &:hover {
            text-decoration: none;
        }
        h2 {
            color: #333;
            font-size: 2.4rem;
        }
        span.category-header__more {
            font-size: 1.8rem;
            color: $color-secondary;
        }
    }

    h2 {
        font-size: 2.4rem;
        &.location-topic {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}

form {
    font-size: 1.6rem;
    ul {
        margin-left: -40px;
    }
    label {
        margin-top: .5rem;
        margin-bottom: 0;
    }
    button {
        background-color: #000f87;
        border: 1px solid #000f87;
        border-radius: 0;
        font-weight: 500;
        font-size: 1em;
        letter-spacing: .075em;
        color: #fff;
        min-width: 145px;
        text-align: center;
    }
}

.atom-event-headline {
    h1 {
        font-size: 5.5rem;
    }
    h2 {
        font-size: 4.5rem;
    }
    h3 {
        font-size: 3.5rem;
    }
}



