.template-selfservice {
  font-size: 1.5rem;
  figure {
    display: block;
    margin-left: 0;
    clear: both;
    border-top: 1px solid $color-light-grey;
  }

   > input,
   figure > div {
     display: none;
   }

   figure > div {
    padding: 20px;
    width: 100%;
    background: $color-white;
  }

  nav {
    h2 {
      padding: 1rem;
      font-weight: bold;
      border-bottom: 1px solid $color-grey;
    }
    label {
      margin: 0.5rem;
      float: left;
      cursor: pointer;
      color: $color-white;
      text-decoration: none;
      padding: .5rem 1rem;
      display: block;
      background-color: $color-main;

      &:hover,
      &:active {
        background-color: $color-secondary;
      }
    }
  }

  #personal-data:checked ~ figure #plenigo-personal-data,
  #personal-order:checked ~ figure #plenigo-personal-order,
  #personal-subscription:checked ~ figure #plenigo-personal-subscription,
  #personal-payment_methods:checked ~ figure #plenigo-personal-payment_methods,
  #personal-address_data:checked ~ figure #plenigo-personal-address_data {
    display: block;
  }

  #personal-data:checked ~ nav label[for="personal-data"],
  #personal-order:checked ~ nav label[for="personal-order"],
  #personal-subscription:checked ~ nav label[for="personal-subscription"],
  #personal-payment_methods:checked ~ nav label[for="personal-payment_methods"],
  #personal-address_data:checked ~ nav label[for="personal-address_data"] {
    position: relative;
    background-color: $color-secondary;
  }

  #personal-data:checked ~ nav label[for="personal-data"]:after,
  #personal-order:checked ~ nav label[for="personal-order"]:after,
  #personal-subscription:checked ~ nav label[for="personal-subscription"]:after,
  #personal-payment_methods:checked ~ nav label[for="personal-payment_methods"]:after,
  #personal-address_data:checked ~ nav label[for="personal-address_data"]:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
  }
}
