.atom-menuitem {

  &__item {
    flex-shrink: 0;
    transition-duration: 0.5s;
    list-style: none;

    &.dropdown > a::after {
      content: "⌄";
      /*color: $color-white;*/
      font-size: 2.4rem;
      line-height: 1.6rem;
      padding-left: .5rem;
      display: inline-block;
    }

    &:hover {
      &> .dropdown-menu {
        visibility: visible;
        opacity: 1;
        display: block;
        z-index: 9;
      }
    }

  }

  &__link {

    &,
    &:link,
    &:visited {
      color: $color-white;
      text-decoration: none;
      padding: .5rem .8rem;
      display: block;
    }

    &.current,
    &:hover {
      background-color: $color-secondary;

      &:after {
        text-decoration: none;
      }
    }
  }

  &__dropdown {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: all .5s ease;
    display: none;
    padding: 1.5rem 0;
    border: 1px solid rgba($color-black,.15);
    border-radius: .4rem;
    max-width: 30rem;
    background-color: $color-white;
  }

}

