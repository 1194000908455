.molecule-cookiebanner {
    &__wrapper {
        display: none;
        position: fixed;
        z-index: 10000;
        width: 100%;
        bottom: 0px;
        left: 0;
        color: $color-white;
        font-size: 1.6rem;


        .container {
            position: relative;
            background-color: transparent;
            padding: 3rem;
            a {
                font-weight: bold;
                cursor: pointer;
                &:hover {
                    color: #0056b3;
                    text-decoration: underline;
                }
            }
            .close_CookieBanner {
                cursor: pointer;
                padding: 0.2rem 1rem;
                margin: 0 3rem;
                border-radius: 3px;
                display: inline-block;
                background-color: $color-button-normal;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    &__bg-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-secondary;
        opacity: 0.95;
    }

}
