.atom-mailchimp {
  font-size: 1.3rem;

  fieldset label {
    font-weight: normal;
    position: relative;
  }

  .button {
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    line-height: 42px;
    width: auto;
    white-space: nowrap;
    height: 42px;
    padding: 0 40px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    border: 0;
    border-radius: 3px;
    vertical-align: top;
    color: #fff;
    background-color: $color-secondary;
    margin: 15px 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .input-field:focus,
  .button:focus {
    outline-color: $color-blue-velvet;
  }

  input.input-field {
    background-color: #fff;
    background-image: none;
    border-radius: 1px;
    color: #333;
    display: block;
    padding: 10px 12px;
    transition: border-color .15s ease-in-out 0s, box-shadow .15s ease-in-out 0s;
    width: 100%;
    font-size: 14px;
    border: 1px solid $color-light-grey2;
    height: 37px;
    max-width: 550px;
  }

  .mc-field-group {
    padding-bottom: 15px;
  }

  #mc_embed_signup input.mce_inline_error {
    border-color: #ea4525;
  }

  #mc_embed_signup div.mce_inline_error {
    margin: 0 0 1em 0;
    padding: 5px 10px;
    background-color: #ea4525;
    font-weight: bold;
    z-index: 1;
    color: #fff;
  }

  #mc_embed_signup div.mce_inline_error {
    z-index: 1 !important;
    color: #ea4525 !important;
    text-align: left !important;
    font-size: 14px !important;
    margin: 0 !important;
    background-color: transparent !important;
    padding: 0 !important;
    padding-top: 5px !important;
  }

  #mc-embedded-subscribe-form input[type=checkbox] {
    margin-right: 10px;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 4px;
  }

  #mc-embedded-subscribe-form .mc_fieldset {
    border: none;
    min-height: 0;
    padding-bottom: 0;
    position: relative;
    text-align: left;
  }

  .response {
    padding: 25px 0;
    font-size: 1.6rem;
    font-weight: bold;
  }


  .content__gdpr label {
    display: block;

    span {
      margin-left: 20px;
      display: block;
    }
  }

  div.inline_error {
    margin: 0 0 1em;
    padding: 5px 10px;
    background-color: #ea4525;
    font-weight: 700;
    z-index: 1;
    color: #fff;
    z-index: 1!important;
    color: #ea4525!important;
    text-align: left!important;
    font-size: 14px!important;
    margin: 0!important;
    background-color: transparent!important;
    padding: 5px 0 0!important;
  }
  label.inline_error {
    color: #ea4525!important;
  }
}
