.molecule-link-list-headline {
  font-weight: bold;
  color: $white;
  margin-bottom: 0;

}

.molecule-link-list-ul {
  li {
    list-style: none;
  }
}
