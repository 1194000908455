@include media-breakpoint-up(md) {
  .template-article__tags {
    font-size: 1.6rem;
    text-align: left;

    .template-article__tags-headline {
      display: inline-block;
    }

    ul,
    li {
      list-style: none;
    }

    ul {
      display: inline-block;
    }

    .template-article__tag-item {
      display: inline-block;

      &:not(:last-child):after {
        content: "\00b7";
        font-size: 2.4rem;
        line-height: 1.6rem;
        padding: 0 1.2rem;
        display: inline-block;
        vertical-align: middle;
        color: #999;
      }
    }
  }
}
