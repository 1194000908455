@include media-breakpoint-up(md) {
  .molecule-sharearticle {
    font-size: 2rem;

    .om-font-icon {
      margin: 0 .5rem;
    }

    &__sharelink {

      &.whatsapp {
        display: none;
      }
    }

  }
}