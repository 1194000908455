.atom-a2hs {
  position: absolute;
  background-color: aliceblue;
  height: 15rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
  padding-top: 5rem;
  -webkit-box-shadow: 0 0 2rem 0 rgba(0,0,0,0.75);
  -moz-box-shadow: 0 0 2rem 0 rgba(0,0,0,0.75);
  box-shadow: 0 0 2rem 0 rgba(0,0,0,0.75);

  .icon-container .atom-logo svg{
    max-height: 6rem;
  }

  &.hide {
    display: none;
  }

  .text-container {
    .title {
      font-family: SourceSansPro-Bold,sans-serif;
      font-size: 2rem;
    }

    .text {
      font-size: 1.6rem;
    }
  }

  #close-a2hs {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 3rem;
    height: 3rem;
  }

  .mdiv {
    height: 3rem;
    width: 2px;
    margin-left: 12px;
    background-color: black;
    transform: rotate(45deg);
    Z-index: 501;
  }

  .md {
    height: 3rem;
    width: 2px;
    background-color: black;
    transform: rotate(90deg);
    Z-index: 502;
  }
}
