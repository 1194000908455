$color-array-grid:
    "black" #000,
    "white" #fff
!default;

.atom-section {
    padding: 30px 0;
    min-height: 40px;
    position: relative;
    z-index: 2;
}

.atom-section.smallSectionOffset {
    margin-top: -105px;
}

.atom-section.mediumSectionOffset {
    margin-top: -200px;
}

.atom-section.largeSectionOffset {
    margin-top: -300px;
}

@each $colorClass, $colorVar in $color-array-grid {
    .atom-section.color-#{$colorClass} {
        background-color: $colorVar;
    }
}

.atom-section.color-none {
    background-color: inherit;
}

.atom-section .a-s__background-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-repeat: no-repeat;
    background-size: cover;
}

.neos-backend .placeholder {
    min-height: auto;
}
