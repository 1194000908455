.my-region-tab-navigation {
  overflow: hidden;
  border-bottom: 1px solid rgba($color-grey,.2);

  &__trigger {
    display: none;
  }

  &__regiontab {
    width: 100%;
    color: white;
    overflow: hidden;
  }

  &__tablabel {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: .5rem 2rem;
    font-size: 1.6rem;
    color: $color-text;
    cursor: pointer;
    margin-bottom: 0;
  }

  &__tablabel::after {
    content: "\276F";
    width: 1.6em;
    height: 1.6em;
    margin-right: -10px;
    text-align: center;
    -webkit-transition: all .35s;
    transition: all .35s;
  }

  &__tabcontent {
    max-height: 0;
    padding: 0;

    color: $color-text;
    -webkit-transition: all .35s;
    transition: all .35s;
  }

  &__link {
    color: $color-main;
    font-size: 1.3rem;
    font-weight: 700;

    &:hover {
      text-decoration: none;
      color: $color-secondary;
      background-color: $color-main;
    }

    &:focus {
      outline: none;
    }

    &:not(:first-child) {
      margin-left: 0;
      background-color: $color-main;
      color: $color-white;

      &:hover {
        color: $color-secondary;
      }
    }

    &:first-child {
      color: $color-grey;
      font-family: $default-font-bold;
      background-color: transparent !important;
      border: 2px solid #cccccc;

      &:hover {
        color: $color-main;
        background-color: transparent;
      }
    }

  }

  &__linkcontainer {
    display: flex;
    justify-content: flex-end;
    margin: 3rem 0 2rem;

    &.is-disabled {
      color: currentColor;
      opacity: 0.5;
      text-decoration: none;

      .my-region-tab-navigation__link {
        color: #CDCDCD !important;
        background-color: #EBEBEB;

      }
      a:hover {
        background-color: $color-grey;
        cursor: not-allowed;
        color: inherit;
      }
    }
  }

  &__trigger[type=checkbox]:checked + &__tablabel::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  &__trigger[type=checkbox]:checked ~ &__tabcontent {
    max-height: 100vh;

  }

  .molecule-myregionmenu__submenu {
    .atom-regiontag__link {
      outline: none;

      &.button-filled {
        width: 100%;
      }

      &.active {
        color: $color-white;
        border-color: $color-secondary;
        background-color: $color-secondary;

      }
    }
  }

}
