.molecule-adcontainer {
  height: 100%;
  margin: 0;

  &__ad {
    font-size: 1.6rem;
    height: 100%;
    min-height: 25rem;

    background-color: lightgrey;
  }
  .molecule-adcontainer > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

body:not(.neos-backend) {
  .molecule-adcontainer > div {
    &:not([id*='skyscraper']) {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 15rem;

      /*&:after {
        content: '';
        position: absolute;
        font-size: 15px;
      }*/
    }
  }
  @include media-breakpoint-up(sm) {
    .molecule-adcontainer > div {
      &:not([id*='skyscraper']) {
        min-height: 25rem;
        /*&:after {
          font-size: 25px;
        }*/
      }
    }
  }
}