.my-region-tab-navigation__linkcontainer {
  display: flex;
  margin: 2rem 2.5rem 1rem 2.5rem;
  justify-content: stretch;
  flex-wrap: nowrap;
  align-items: stretch;
  padding-left:2.5rem;
  padding-right:2.5rem;

  .my-region-tab-navigation__button {
    flex-grow: 1;
    font-family: SourceSansPro-Bold, sans-serif;
    padding: .8rem 2rem;
    border-radius: .5rem;
    font-size: 1.3rem;
    font-weight: 700;
    white-space: nowrap;

    &.my-region-delete {
      color: #999;
      background-color: transparent !important;
      border: 2px solid #ccc;

      &:hover {
        color: $color-main;
      }
    }

    &.my-region-choose {
      border: 2px solid $color-main;
      background-color: $color-main;
      color: $color-white;
      margin-left: 15px;

      &:hover {
        color: $color-secondary;
      }
    }

    &.my-region-delete,
    &.my-region-choose{
      &[disabled=true],
      &[disabled=true]:hover {
        color: $color-light-grey2;
        background-color: transparent;
        border: 2px solid $color-light-grey2;
      }
    }
  }
}

.molecule-myregiontaglist {
  border-radius: .25rem;
  background-color: $color-white;
  margin: 1rem;
  transition: all .5s ease;

  .molecule-myregiontaglist__wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: first baseline;
    margin-bottom: 10px;
  }

  &.dropdown-menu.show {
    opacity: 1;
    display: block;
    visibility: visible;
    z-index: 999;
  }

  &__accordion {
    display: none;
  }

  &__submenu {
    padding: 1.5rem 0;
  }

  &__smalltext {
    white-space: normal;
  }

  .molecule-myregiontaglist__header {
    font-size: 1.8rem;
    font-weight: 700;
    color: $color-main;
    padding: .5rem 1.5rem 0;
    margin: .5rem 0 .8rem;
  }

  .molecule-myregiontaglist__taglist {
    padding: 0 1.5rem;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  .molecule-myregiontaglist__tag {
    list-style: none;

    &:hover {
      background: transparent !important;
    }
  }

  .molecule-myregiontaglist__taglink {
    border-radius: .3rem;
    display: block;
    padding: .2rem .7rem !important;
    font-size: 1.2rem !important;
    margin: .3rem !important;
    border-bottom: 0 !important;
    background-color: $color-white;

    &:hover {
      color: #fff;
      background-color: $color-secondary;
      text-decoration: none;
    }
  }

  .molecule-myregiontaglist__tag:not(:last-child) .molecule-myregiontaglist__chiplink {
    margin-right: .3rem !important;
  }
}
