.molecule-myregionmenu {

  &__accordion {
    width: 100%;
    background-color: inherit;
    border: 0;
    text-align: left;
    padding: .3rem 1.5rem;
    font-size: 1.6rem;
    line-height: 3.7rem;

    &:after {
      content: '\f105';
      font-size: 2.2rem;
      color: #777;
      font-weight: bold;
      float: right;
      margin-left: 5px;
    }

    &:hover {
      background-color: #ccc;
    }

    &:active,
    &:focus {
      outline: none;
      border: none;
    }
  }

  &__submenu {
    visibility: visible;
    opacity: 1;
    padding-bottom: 1rem;
    display: block;

    li:hover {
      background: $color-light-grey;
    }

    &__item:not(:last-child) &__link {
      border-bottom: 1px solid red;
    }

  }

  &__smalltext {
    font-size: 1.4rem;
    padding: 0 1.5rem;
  }


  &__header {
    font-size: 1.8rem;
    font-weight: 700;
    color: $color-main;
    padding: .5rem 1.5rem 0;
    margin: 1rem 0 .8rem;
  }

  &__submenu &__header {
    border-top: 1px solid #e9ecef; // TODO: color variable
  }

  /* Accordion styles */
  .trigger {
    display: none;
  }
  .region-tabs {
    overflow: hidden;
  }


  .region-tab {
    width: 100%;
    color: white;
    overflow: hidden;
  }
  .tab-label {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: .5rem 1.6rem;
    font-size: 1.6rem;
    color: $color-text;
    cursor: pointer;
    margin-bottom: 0;

    background: #fafafa;
  }
  .tab-label:hover {
    background: #f0f0f0;
  }
  .tab-label::after {
    content: "\276F";
    width: 1.6em;
    height: 1.6em;
    text-align: center;
    -webkit-transition: all .35s;
    transition: all .35s;
  }
  .tab-content {
    max-height: 0;
    padding: 0;

    color: $color-text;
    -webkit-transition: all .35s;
    transition: all .35s;
  }
  .tab-close {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #2c3e50;
    cursor: pointer;
  }
  .tab-close:hover {
    background: #1a252f;
  }

  .trigger[type=checkbox]:checked + .tab-label {
    background: #f0f0f0;
  }

  .trigger[type=checkbox]:checked + .tab-label::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .trigger[type=checkbox]:checked ~ .tab-content {
    max-height: 100vh;

  }



}
